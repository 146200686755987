import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import "./Configuration.css";
import SwitchButton from "../../common/switchButton/SwitchButton";
import { getRoles, modifyRoles, updateRole } from "../../../redux/userActions";
import AddRole from "../../components/addRole/AddRole";

import Illustration from "/error/permissions.svg";
import { useDispatch } from "react-redux";
import { callPopup } from "../../../store/slices/popupSlice";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export default function Configuration() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRoleList, setSelectedRoleList] = React.useState([]);
  const [roles, setRoles] = React.useState("");
  const [selectedRole, setSelectedRole] = React.useState([]);
  const [selectedPermission, setSelectedPermission] = React.useState([]);
  const [permissionsChanged, setPermissionsChanged] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [analyticUpdated, setAnalyticUpdated] = React.useState(false);

  const [currentTab, setCurrentTab] = useState("permission");

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  const handleRoleSelectChange = (event) => {
    setRoles(event.target.value);
    setPermissionsChanged(false); // Reset permission changes when role changes
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await getRoles();
      setSelectedRoleList(result?.data);
      setRoles(result?.data?.[0]?._id);
    };

    fetchData();
  }, [analyticUpdated]);

  React.useEffect(() => {
    if (roles) {
      const roleData = selectedRoleList?.find((role) => role?._id === roles);
      setSelectedPermission(roleData?.permissions || []);
      setSelectedRole(roleData);
    }
  }, [roles, selectedRoleList]);

  const handlePermissionChange = (id, value) => {
    setSelectedPermission((prevPermissions) =>
      prevPermissions.map((permission) =>
        permission.id === id ? { ...permission, value: value } : permission
      )
    );
    setPermissionsChanged(true);
  };

  const handleRoleChange = async (id, value) => {
    const role = selectedRoleList.find((role) => role._id === id);

    const data = {
      roleId: id,
      isActive: value,
      permissions: role?.permissions,
    };
    const res = await updateRole(data);
    if (res?.data?.success === true) {
      toast.success(res?.data?.data?.name + " " + t("is_updated"));
    } else {
      toast.error(res.data.message);
    }
  };

  const handleUpdatePermissions = async () => {
    // Handle saving changes here
    // You can send the modified permissions to your backend or handle them locally

    const apiData = {
      roleId: selectedRole?._id,
      isActive: selectedRole?.isActive,
      permissions: selectedPermission,
    };

    try {
      const res = await modifyRoles(apiData);
      setPermissionsChanged(false);
    
      if (res.success === true) {
        dispatch(
          callPopup({
            open: true,
            type: "success",
            page: "update-role",
            message: t("user_permissions_updated"),
          })
        );
      } else {
        dispatch(
          callPopup({
            open: true,
            type: "error",
            page: "update-role",
            title: res.data.message,
            message: res.data.data,
          })
        );
      }
    } catch (error) {
      console.error("API Call Error:", error);
    }
  };

  return (
    <section className="Page__main_wrapper">
      <div className="Page__tab_select_space">
        <div className="tab__btn_group">
          <div
            className={`tab__button ${currentTab === "permission" && "active"}`}
            onClick={() => handleTabChange("permission")}
          >
            <h1 className="tab__btn_text_fs"> {t("permissions")}</h1>
          </div>
          <div
            className={`tab__button ${currentTab === "role" && "active"}`}
            onClick={() => handleTabChange("role")}
          >
            <h1 className="tab__btn_text_fs">{t("roles")}</h1>
          </div>
        </div>
      </div>
      {currentTab === "role" && (
        <div className="Page__action_wrapper">
          <div className="Page__left_actions">
            <button
              onClick={() => setOpenPopup(true)}
              className="mi_btn mi_btn_medium mi_btn_primary"
            >
              <i className="fa-solid fa-plus"></i>
              <span>{t("add_roles")}</span>
            </button>
          </div>
        </div>
      )}

      <div className="Configuration__container_grid">
        {currentTab === "permission" && (
          <div className="Configuration__container">
            <div className="Page__input_forms">
              <div className="Input__column">
                <p className="Input__label_fs">{t("select_user_role")}</p>

                <div className="Configuration__container">
                  <FormControl
                    fullWidth
                    placeholder="Select your Organization Level"
                  >
                    <Select value={roles} onChange={handleRoleSelectChange}>
                      {selectedRoleList?.map((role) => (
                        <MenuItem key={role?._id} value={role?._id}>
                          {role?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="Configuration__header">
              <h1 className="ModalPopup__form_section_heading_fs">
                {t("permissions")}
              </h1>
              <button
                className={`mi_btn mi_btn_medium ${
                  permissionsChanged ? "mi_btn_primary" : "mi_btn_disabled"
                }`}
                onClick={handleUpdatePermissions}
                disabled={!permissionsChanged}
              >
                <i className="fa-regular fa-circle-check"></i>
                <span>{t("update_permissions")}</span>
              </button>
            </div>

            <div className="Configuration__grid_layout">
              <div className="Configuration__action_list">
                <div className="Configuration__action_card_list">
                  {selectedPermission?.length > 0 ? (
                    selectedPermission.map((permission, index) => (
                      <ConfigurationAction
                        key={index}
                        id={permission.id}
                        title={permission.name}
                        defaultValue={permission.value}
                        handlePermissionChange={handlePermissionChange}
                      />
                    ))
                  ) : (
                    <div className="Permissions__empty">
                      <div className="Permissions__image">
                        <img src={Illustration} alt="" />
                      </div>
                      <h2 className="Permissions__subtitle_fs">
                        {t("no_permissions_msg")}
                      </h2>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {currentTab === "role" && (
          <div className="Configuration__container">
            <div className="Configuration__header">
              <h1 className="ModalPopup__form_section_heading_fs">
                {t("roles")}
              </h1>
              <button className={`mi_btn mi_btn_medium mi_btn_disabled`}>
                <i className="fa-regular fa-circle-check"></i>
                <span>{t("update_roles")}</span>
              </button>
            </div>

            <div className="Configuration__grid_layout">
              <div className="Configuration__action_list">
                <div className="Configuration__action_card_list">
                  {selectedRoleList?.length > 0 ? (
                    selectedRoleList.map((role, index) => (
                      <ConfigurationRoleAction
                        key={index}
                        id={role._id}
                        title={role.name}
                        defaultValue={role.isActive}
                        handleRoleChange={handleRoleChange}
                      />
                    ))
                  ) : (
                    <div className="Permissions__empty">
                      <div className="Permissions__image">
                        <img src={Illustration} alt="" />
                      </div>
                      <h2 className="Permissions__subtitle_fs">
                        {t("no_permissions_msg")}
                      </h2>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AddRole
        open={openPopup}
        onClose={handleClosePopup}
        setAnalyticUpdated={setAnalyticUpdated}
      />
    </section>
  );
}

function ConfigurationAction({
  id,
  title,
  defaultValue,
  handlePermissionChange,
}) {
  const [checked, setChecked] = React.useState(defaultValue);

  React.useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]); // Update the switch state when defaultValue changes

  const handleCheckedChange = (event) => {
    const newValue = event.target.checked;
    setChecked(newValue);
    handlePermissionChange(id, newValue);
  };
  return (
    <div className="ConfigurationAction">
      <h1 className="ConfigurationAction__title_fs">{title}</h1>
      <SwitchButton value={checked} handleChange={handleCheckedChange} />
    </div>
  );
}

function ConfigurationRoleAction({
  id,
  title,
  defaultValue,
  handleRoleChange,
}) {
  const [checked, setChecked] = React.useState(defaultValue);

  React.useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]);

  const handleCheckedChange = (event) => {
    const newValue = event.target.checked;
    setChecked(newValue);
    handleRoleChange(id, newValue);
  };
  return (
    <div className="ConfigurationAction">
      <h1 className="ConfigurationAction__title_fs">{title}</h1>
      <SwitchButton value={checked} handleChange={handleCheckedChange} />
    </div>
  );
}
